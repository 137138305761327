import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import PageTitle from "../components/PageTitle";

import leftImg from "../images/home/mobile-phone.png";
import rightImg from "../images/home/home-right.png";

const Home = () => {
  return (
    <>
      <PageTitle title="Empowering Seniors in Technology | Bloom IT" />
      <div className="container-main">
        <div className="home-banner">
          <h1 className="home-banner-text">
            Empowering Seniors, Embracing Technology, Blooming Together.
          </h1>
          <a
            className="btn btn-custom-secondary home-banner-btn"
            href="/courses"
          >
            Browse our courses
          </a>
        </div>

        <Container className="home-main-container">
          <Row className="home-images">
            <Col>
              <div>
                <Image
                  src={leftImg}
                  alt="Mobile phone showing social media apps"
                  fluid
                ></Image>
              </div>
            </Col>
            <Col sm={1}></Col>
            <Col sm={7}>
              <div>
                <Image
                  src={rightImg}
                  alt="Class of seniors posing for the camera"
                  fluid
                ></Image>
              </div>
            </Col>
          </Row>
          <Row className="home-partner">
            <Col sm={5} md={4} lg={3}>
              <div className="home-partner-color-blocks">
                <div className="color-blocks color-blocks--1"></div>
                <div className="color-blocks color-blocks--2">
                  <p>PARTNER WITH US</p>
                </div>
              </div>
            </Col>
            <Col>
              <div className="home-partner-text">
                If you share our passion for empowering seniors through
                technology and believe in the value of digital inclusion, we
                would love to explore partnership opportunities with you.
                Whether you represent a company, organization, or community
                group, we welcome collaborations that align with our mission and
                values.
                <br />
                <br />
                Get in touch with us today to discuss how we can partner
                together to make a positive impact in the lives of seniors in
                our community.
                <br />
                <br />
                Email:{" "}
                <span className="home-partner-email">
                  <a href="mailto:admin@bloomit.sg">admin@bloomit.sg</a>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
