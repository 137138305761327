import Spinner from 'react-bootstrap/Spinner';

const LoadingIcon = () => {
  return (
    <div className='empty-container'>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default LoadingIcon;