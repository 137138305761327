import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import { FaCookieBite } from "react-icons/fa6";

const CookieConsentAlert = ({ onAccept, onDecline }) => {
  return <div className="alert-main-container">
    <Alert className="alert-custom-widget" key="secondary" variant="secondary">
      <Alert.Heading><FaCookieBite />&nbsp;Cookies Consent Notice</Alert.Heading>
      <p>
        To find out more about cookies, what they are and how we use them,
        please see our <a href='/data-protection-notice'>privacy notice</a>,
        which also provides information on how to delete cookies from your hard drive.
      </p>
      <div className='alert-button-container'>
        <Button variant="custom-secondary" onClick={onAccept}>Accept</Button>
        <Button variant="custom-secondary" onClick={onDecline}>Decline</Button>
      </div>
    </Alert>
  </div>
}

export default CookieConsentAlert;
