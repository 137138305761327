import { useState, useEffect } from "react";
import { getDataHelper } from "../requests/PostRequests";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import LoadingIcon from '../components/LoadingIcon';
import { timeout } from "../helpers/Sleep";

import biscuitImg from "../images/team/About-Us-Biscuit-Photo-v1.png";

const CardContent = ({ trainer }) => {
  return (
    <>
      <Image
        className="mx-auto d-block border border-custom-secondary trainer-img"
        src={trainer.image_url}
        roundedCircle
        alt={`Image of ${trainer.name}`}
      />
      <Card.Body>
        <Card.Title style={{ marginBottom: "10px" }}>
          {trainer.name}
        </Card.Title>
        <Card.Subtitle style={{ marginBottom: "10px" }}>
          {trainer.role}
        </Card.Subtitle>
        <Card.Text>{trainer.description}</Card.Text>
      </Card.Body>
    </>
  );
};

const DisplayCards = ({ trainers }) => {

  return (
    <Row xs={1} sm={1} md={2} xl={3} xxl={3} className="g-4">
      {trainers.map(function (trainer, idx) {
        return (
          <Col key={idx}>
            <Card
              className="h-100"
              key={`trainer_card_${idx}`}
              border="muted"
              style={{
                padding: "10px",
                margin: "10px",
                display: "flex",
              }}
            >
              <CardContent trainer={trainer} />
            </Card>
          </Col>
        );
      })
      }
    </Row>
  )
};

const Team = () => {
  const [team, setTeam] = useState([]);

  // For loading icon
  const [isLoading, setIsLoading] = useState(true);
  const startLoading = () => setIsLoading(true);
  const stopLoading = async () => {
    const timeoutDuration = 200;
    await timeout(timeoutDuration);
    setIsLoading(false);
  }

  async function getTeam() {
    const onSuccess = (data) => {
      let newTeam = [];
      const rows = data.result.rows;
      const length = rows.length;

      for (let i = 0; i < length; i++) {
        newTeam.push({
          id: parseInt(rows[i].id),
          name: rows[i].name,
          role: rows[i].role,
          description: rows[i].description,
          image_url: rows[i].image_url,
        });
      }

      setTeam(newTeam.sort((a, b) => a.id - b.id));
      stopLoading();
    };

    const onError = (e) => {
      console.error("Error from fetching getTeam: Error = " + e);
      stopLoading();
    };

    startLoading();
    await getDataHelper("/v1/trainers",
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess,
      onError);
  }

  useEffect(() => {
    async function fetchData() {
      await getTeam();
    }

    fetchData();
  }, []);

  return (
    <>
      <PageTitle title="Bloom IT Team | Bloom IT" />
      <div className="container-content-fixed">
        <Header title="Team" />
        {
          !isLoading
            ? <DisplayCards trainers={team} />
            : <LoadingIcon />
        }
        <div className="team-biscuit">
          <div className="team-biscuit-left">
            <div className="team-biscuit-left--heading">
              <h2>Passionate Trainers That Make a Difference</h2>
              <hr className="biscuit-horiz-line" />
            </div>
            <p>
              Meet our passionate trainers who make a difference every day! 💫 With dedication and expertise,
              they empower seniors to embrace technology with confidence and joy.
              Join us on our mission to create a brighter digital future for all generations.
              <br />
              <br />
              #EmpoweringSeniors #TechForAll
            </p>
          </div>
          <div className="team-biscuit-right">
            <img
              className="team-biscuit-img"
              src={biscuitImg}
              alt="Image of a passionate trainer"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
