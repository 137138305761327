import Table from 'react-bootstrap/Table';

import PageTitle from '../components/PageTitle';
import Header from "../components/Header";

const PersonalDataProtectionNotice = () => {
  return (
    <>
      <PageTitle title="Privacy and Cookie Policies | Bloom IT" />
      <div className="container-content-fixed">
        <div className="courses-hdr-grp">
          <Header title="Data Protection Notice" />
        </div>
        <p>
          This Data Protection Notice (“<b>Notice</b>”) sets out the basis which Bloom IT Pte. Ltd. (“<b>we</b>”, “<b>us</b>”, or “<b>our</b>”)
          may collect, use, disclose or otherwise process personal data of our customers in accordance with the
          Personal Data Protection Act (“<b>PDPA</b>”). This Notice applies to personal data in our possession or under
          our control, including personal data in the possession of organisations which we have engaged to collect,
          use, disclose or process personal data for our purposes.
        </p>
        <br />
        <h2>
          How We Collect Your Personal Data
        </h2>
        <p>
          As used in this Notice:
          <p />
          <ol type='a'>
            <li>
              “<b>customer</b>” means an individual who
              <ol type='i'>
                <li>
                  has contacted us through any means to
                  find out more about any goods or services we provide, or
                </li>
                <li>
                  may, or has, entered into a
                  contract with us for the supply of any goods or services by us; and
                </li>
              </ol>
            </li>
            <li>
              “<b>personal data</b>” means data, whether true or not,
              about a customer who can be identified:
              <ol type='i'>
                <li>from that data; or</li>
                <li>from that data and other information to which we have or are likely to have access.</li>
              </ol>
            </li>
          </ol>
        </p>
        <p>
          Depending on the nature of your interaction with us, some examples of personal data which we
          may collect from you include name, email address, telephone number, nationality, gender and date of birth.
        </p>
        <p>
          Other terms used in this Notice shall have the meanings given to them in the PDPA (where the context so permits).
        </p>
        <br />
        <h2>
          Collection, Use and Disclosure of Personal Data
        </h2>
        <p>
          We generally do not collect your personal data unless
          <p />
          <ol type='a'>
            <li>
              it is provided to us voluntarily by you directly
              or via a third party who has been duly authorised by you to disclose your personal data to
              us (your “authorised representative”) after
              <ol type='i'>
                <li>
                  you (or your authorised representative)
                  have been notified of the purposes for which the data is collected, and
                </li>
                <li>
                  you (or your authorised representative) have provided written consent to the collection and usage of
                  your personal data for those purposes, or
                </li>
              </ol>
            </li>
            <li>
              collection and use of personal data without consent is
              permitted or required by the PDPA or other laws.We shall seek your consent before collecting any additional
              personal data and before using your personal data for a purpose which has not been notified to you
              (except where permitted or authorised by law).
            </li>
          </ol>
        </p >
        <p>
          We may collect and use your personal data for any or all of the following purposes:
        </p>
        <ol type='a'>
          <li>
            performing obligations in the course of or in connection with our provision of the goods and/or services requested by you;
          </li>
          <li>
            verifying your identity;
          </li>
          <li>
            responding to, handling, and processing queries, requests, applications, complaints, and feedback from you;
          </li>
          <li>
            managing your relationship with us;
          </li>
          <li>
            processing payment or credit transactions;
          </li>
          <li>
            complying with any applicable laws, regulations, codes of practice, guidelines, or rules, or to assist in
            law enforcement and investigations conducted by any governmental and/or regulatory authority;
          </li>
          <li>
            any other purposes for which you have provided the information;
          </li>
          <li>
            transmitting to any unaffiliated third parties including our third party service providers and agents, and
            relevant governmental and/or regulatory authorities, whether in Singapore or abroad, for the aforementioned
            purposes; and
          </li>
          <li>
            any other incidental business purposes related to or in connection with the above.
          </li>
        </ol>
        <p>
          We may disclose your personal data:
        </p>
        <ol type='a'>
          <li>
            where such disclosure is required for performing obligations in the course of or in connection with our
            provision of the goods and services requested by you; or
          </li>
          <li>
            to third party service providers, agents and other organisations we have engaged to perform any of the
            functions with reference to the above mentioned purposes.
          </li>
        </ol>
        <br />
        <h2>
          Withdrawing Your Consent
        </h2>
        <p>
          The consent that you provide for the collection, use and disclosure of your personal data will remain valid
          until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop
          collecting, using and/or disclosing your personal data for any or all of the purposes listed above by submitting
          your request in writing or via email to our Data Protection Officer at the contact details provided below.
        </p>
        <p>
          Upon receipt of your written request to withdraw your consent, we may require reasonable time (depending on the
          complexity of the request and its impact on our relationship with you) for your request to be processed and
          for us to notify you of the consequences of us acceding to the same, including any legal consequences which
          may affect your rights and liabilities to us. In general, we shall seek to process your request within <b>five (5)
            business days</b> of receiving it.
        </p>
        <p>
          Whilst we respect your decision to withdraw your consent, please note that depending on the nature and scope of
          your request, we may not be in a position to continue providing our goods or services to you and we shall,
          in such circumstances, notify you before completing the processing of your request. Should you decide to cancel
          your withdrawal of consent, please inform us in writing in the manner described above.
        </p>
        <p>
          Please note that withdrawing consent does not affect our right to continue to collect, use and disclose personal
          data where such collection, use and disclose without consent is permitted or required under applicable laws.
        </p>
        <br />
        <h2>
          Access to and Correction of Personal Data
        </h2>
        <p>
          If you wish to make
          <p />
          <ol type='a'>
            <li>
              an access request for access to a copy of the personal data which we hold about you or
              information about the ways in which we use or disclose your personal data, or
            </li>
            <li>
              a correction request to correct
              or update any of your personal data which we hold about you, you may submit your request in writing or via email
              to our Data Protection Officer at the contact details provided below.
            </li>
          </ol>
        </p>
        <p>
          Please note that a reasonable fee may be charged for an access request. If so, we will inform you of the fee
          before processing your request.
        </p>
        <p>
          We will respond to your request as soon as reasonably possible. In general, our response will be <b>within five
            (5) business days</b>. Should we not be able to respond to your request <b>within thirty (30)</b> days after receiving
          your request, we will inform you in writing <b>within thirty (30) days</b> of the time by which we will be able to
          respond to your request. If we are unable to provide you with any personal data or to make a correction requested
          by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not
          required to do so under the PDPA).
        </p>
        <br />
        <h2>
          Protection of Personal Data
        </h2>
        <p>
          To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modification,
          disposal or similar risks, we have introduced appropriate administrative, physical and technical measures such as
          minimised collection of personal data, authentication and access controls (such as good password practices,
          need-to-basis for data disclosure, etc.), encryption of data, data anonymisation, up-to-date antivirus protection,
          regular patching of operating system and other software, securely erase storage media in devices before disposal,
          web security measures against risks, usage of one time password(OTP)/2 factor authentication (2FA)/multi-factor
          authentication (MFA) to secure access, and security review and testing performed regularly.
        </p>
        <p>
          You should be aware, however, that no method of transmission over the Internet or method of electronic storage
          is completely secure. While security cannot be guaranteed, we strive to protect the security of your information
          and are constantly reviewing and enhancing our information security measures.
        </p>
        <br />
        <h2>
          Accuracy of Personal Data
        </h2>
        <p>
          We generally rely on personal data provided by you (or your authorised representative). In order to ensure that
          your personal data is current, complete and accurate, please update us if there are changes to your personal data
          by informing our Data Protection Officer in writing or via email at the contact details provided below.
        </p>
        <br />
        <h2>
          Retention of Personal Data
        </h2>
        <p>
          We may retain your personal data for as long as it is necessary to fulfil the purpose for which it was collected,
          or as required or permitted by applicable laws.
        </p>
        <p>
          We will cease to retain your personal data, or remove the means by which the data can be associated with you,
          as soon as it is reasonable to assume that such retention no longer serves the purpose for which the personal
          data was collected, and is no longer necessary for legal or business purposes.
        </p>
        <br />
        <h2>
          Transfers of Personal Data Outside of Singapore
        </h2>
        <p>
          We generally do not transfer your personal data to countries outside of Singapore. However, if we do so, we will
          obtain your consent for the transfer to be made and we will take steps to ensure that your personal data
          continues to receive a standard of protection that is at least comparable to that provided under the PDPA.
        </p>
        <br />
        <h2>
          Data Protection Officer
        </h2>
        <p>
          You may contact our Data Protection Officer if you have any enquiries or feedback on our personal data
          protection policies and procedures, or if you wish to make any request, in the following manner:
        </p>
        <Table bordered responsive>
          <tbody>
            <tr>
              <td><b>Contact No</b></td>
              <td>+65 87793669</td>
            </tr>
            <tr>
              <td><b>Email Address</b></td>
              <td><a href='mailto:admin@bloomit.sg'>admin@bloomit.sg</a></td>
            </tr>
          </tbody>
        </Table>
        <br />
        <h2>
          Cookie Policy
        </h2>
        <p>
          This policy describes the use of cookies and other technologies. Like many websites, Bloom IT! uses
          automated technologies on the Site, such as first-party cookies and third-party cookies on the Site.
          By using the Site, you consent to our use of cookies and other technologies as described below.
        </p>
        <br />
        <h2>
          What are Automated Technologies
        </h2>
        <p>
          Cookies are text files containing small amounts of information which are downloaded to your computer or
          other electronic devices when you visit a website or mobile application. Cookies are then sent back to
          the originating site on each subsequent visit, or to another site that recognizes the cookies.
        </p>
        <p>
          We may employ cookies and other technologies as follows:
          <ol type='a'>
            <li>
              tracking information such as the number of visitors and their frequency of use, profiles of visitors and their preferred sites
            </li>
            <li>
              making our websites and platforms easier to use. For example, cookies may be used to help speed up your future interactions with our websites and platforms
              to better tailor our products and services to your interests and needs
            </li>
            <li>
              collating information on a user's search and browsing history
            </li>
            <li>
              when you interact with us on our websites and platforms, we may automatically receive and record information on
              our server logs from your browser. We may collect for the purposes of analysis, statistical and
              site-related information including, without limitation, information relating to how a visitor arrived
              at the website or platform, the browser used by a visitor, the operating system a visitor is using, a
              visitor's IP address, and a visitor's click stream information and time stamp (which may include for
              example, information about which pages they have viewed, the time the pages were accessed, and the time
              spent per web page)
            </li>
            <li>
              using such information to understand how people use our websites and platforms, and to help us improve
              their structure and contents
            </li>
          </ol>
        </p>
        <br />
        <h2>
          How to Manage Your Cookies
        </h2>
        <p>
          Most browsers allows your to modify, delete cookies and manage your browsing preferences. You are able to
          refuse cookies or to delete certain cookies by looking into your browser's settings.
        </p>
        <p>
          Do note that if you choose to block cookies, you may prevent the Site from performing certain actions. If you
          choose to delete the cookies in the future or access the website with different browser, you will need to opt in again.
        </p>
        <br />
        <h2>
          Third-Party Sites and Links
        </h2>
        <p>
          You may access third-party web sites through this Site, and those third-party web sites may collect personal
          information about you. This Privacy Policy does not cover the information practices of third-party web sites.
          If you have any questions about the personal information collected by third-party web sites, including how
          they collect and use personal information, you should contact the third-parties directly.
          Bloom IT! is not responsible for the content or privacy practices of other sites.
        </p>
        <br />
        <h2>
          Effect of Notice and Changes to Notice
        </h2>
        <p>
          This Notice applies in conjunction with any other notices, contractual clauses and consent clauses that apply
          in relation to the collection, use and disclosure of your personal data by us.
        </p>
        <p>
          We may revise this Notice from time to time without any prior notice. You may determine if any such revision
          has taken place by referring to the date on which this Notice was last updated. Your continued use of our
          services constitutes your acknowledgement and acceptance of such changes.
        </p>
      </div>
    </>
  );
};

export default PersonalDataProtectionNotice;
