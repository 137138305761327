import Image from "react-bootstrap/Image";
import { FaHandHoldingHeart, FaLightbulb, FaChartLine } from "react-icons/fa6";

import PageTitle from "../components/PageTitle";
import Header from "../components/Header";

import partner1Logo from "../images/about-us/U-Live-Logo.png";
import partner2Logo from "../images/about-us/raiSE-Logo.png";
import partner3Logo from "../images/about-us/CDAC-Logo.png";

const AboutUs = () => {
  return (
    <>
      <PageTitle title="About Us | Bloom IT" />
      <div className="container-main">
        <Header title="About Us" addClassName="abt-us-header" />
        <div
          className="abt-us-hero-container"
        >
          <h1 className="abt-us-hero-header">About Us</h1>
          <h3 className="abt-us-hero-text">
            At Bloom IT, we provide affordable and accessible IT and smartphone
            classes tailored for seniors. Recognizing that many seniors feel
            lost and lack confidence with technology, we aim to simplify
            learning and instill confidence in a supportive environment.
          </h3>
          <a className="btn btn-custom-primary" href="/team">
            Meet our team
          </a>
        </div>

        <div className="abt-us-who-container">
          <h2 className="abt-us-who-header">WHO WE ARE</h2>
          <p className="abt-us-who-text">
            Bloom IT was founded by Jodie, who started her journey as an
            assistant in a small social enterprise while pursuing full-time
            studies and part-time jobs to sustain herself. Recognizing the
            passion of trainers for seniors and the challenges seniors face in
            embracing technology, Jodie developed a deep desire to help seniors.
            She transitioned to become a trainer and later took on leadership
            roles, eventually founding Bloom IT to further support seniors.
            Bloom IT aims to reignite seniors' passion for technology, making
            learning fun and easy while providing personalized support to help
            seniors improve and rediscover their love for technology.
            <br />
            <br />
            Our classes facilitate improved connectivity with family, reduce
            feelings of isolation, and open up potential employment
            opportunities. Ultimately, we strive to boost seniors' confidence,
            enhance their mental well-being, and promote social integration
            through collaborative learning experiences. Join us in empowering
            seniors to navigate the digital world with ease and confidence.
          </p>
        </div>

        <div className="abt-us-tri-blocks">
          <div className="abt-us-block abt-us-block--1">
            <div className="abt-us-block-header">
              <span className="vert-line"></span>
              <FaHandHoldingHeart className="abt-us-icon" fixedWidth />
              <h3>Benevolence</h3>
            </div>
            We prioritize benevolence in the approach to teaching seniors in
            technology. We believe in showing kindness, compassion, and
            understanding towards older learners who may feel apprehensive or
            overwhelmed by new technologies. By cultivating a sense of
            benevolence, We aim to create a supportive and nurturing learning
            environment where seniors feel valued and encouraged to explore the
            digital world at their own pace.
          </div>
          <div className="abt-us-block abt-us-block--2">
            <div className="abt-us-block-header">
              <span className="vert-line"></span>
              <FaLightbulb className="abt-us-icon" fixedWidth />
              <h3>Belief</h3>
            </div>
            Another important value we hold as a team is belief - belief in the
            potential of every senior learner to embrace technology and adapt to
            new digital tools and devices. We have unwavering faith in the
            capacity of older adults to learn and grow, and We are committed to
            fostering a positive mindset that empowers seniors to overcome
            challenges and achieve their goals in the realm of technology.
            Through belief, We inspire confidence and motivation, instilling in
            seniors the belief that they can succeed in mastering new
            technological skills.
          </div>
          <div className="abt-us-block abt-us-block--3">
            <div className="abt-us-block-header">
              <span className="vert-line"></span>
              <FaChartLine className="abt-us-icon" fixedWidth />
              <h3>Building Confidence</h3>
            </div>
            We place great emphasis on building confidence in seniors as they
            navigate the world of technology. We believe in equipping older
            learners with the knowledge, skills, and support they need to feel
            confident and capable in using digital devices and applications. By
            building confidence, We empower seniors to overcome any fears or
            doubts they may have about technology, enabling them to fully
            embrace its benefits and integrate it into their daily lives with
            assurance and self-assurance.
          </div>
        </div>

        <div className="abt-us-who-container">
          <h2 className="abt-us-who-header">OUR PARTNERS</h2>
          <div className="abt-us-partners">
            <Image
              className="abt-us-partner-logo abt-us-partner-logo--1"
              src={partner1Logo}
              alt="Partner logo - U Live"
            />
            <Image
              className="abt-us-partner-logo"
              src={partner2Logo}
              alt="Partner logo - raiSE"
            />
            <Image
              className="abt-us-partner-logo"
              src={partner3Logo}
              alt="Partner logo - CDAC"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
