import PropTypes from "prop-types";

const Header = ({title, addClassName}) => {
  const concatenatedClassVal = addClassName
    ? "header " + addClassName
    : "header";

  return (
    <header className={concatenatedClassVal}>
      <div className="input-cell">
        <h1>{title}</h1>
      </div>
    </header>
  );
};

Header.defaultProps = {
  title: "Title",
  addClassName: "",
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  addClassName: PropTypes.string
};

export default Header;
