import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import LoadingIcon from "../components/LoadingIcon";
import { getDataHelper } from "../requests/PostRequests";
import { timeout } from "../helpers/Sleep";

const CardContent = ({ item }) => {
  const backgroundUrl = "url(" + item.image_url + ")";

  return (
    <>
      <div className='card-image' style={{ backgroundImage: backgroundUrl, flexShrink: "0" }}></div>
      <Card.Body style={{ flexShrink: "0" }}>
        <Card.Title style={{ marginBottom: "1rem" }}>
          {item.title}
        </Card.Title>
        <Card.Text style={{ marginBottom: "2rem" }}>{item.description}</Card.Text>
        {item.user_guide_resources.length > 0 ?
          <>
            <Card.Subtitle style={{ marginBottom: "1rem" }}>Links:</Card.Subtitle>
            <ul>
              {item.user_guide_resources.map(function (item, _) {
                return (
                  <li key={item.id + '_resource'}>
                    <a href={item.url} target="_blank">{item.name}</a>
                  </li>
                );
              })}
            </ul>
          </>
          : <div style={{ marginBottom: "1rem" }}></div>}
      </Card.Body>
    </>
  );
}

const DisplayCards = ({ resources }) => {

  return (
    <Row xs={1} sm={1} md={2} xl={3} xxl={4} className="g-4">
      {resources.map(function (item, idx) {
        return (
          <Col key={idx + '_col'}>
            <Card
              className="h-100"
              key={idx + '_card'}
              border="muted"
              style={{
                margin: "10px",
                display: "flex",
                overflow: "auto",
              }}
            >
              <CardContent item={item} />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

const Resources = () => {

  const [resources, setResources] = useState([]);

  // For loading icon
  const [isLoading, setIsLoading] = useState(true);
  const startLoading = () => setIsLoading(true);
  const stopLoading = async () => {
    const timeoutDuration = 200;
    await timeout(timeoutDuration);
    setIsLoading(false);
  }

  async function getUserGuides() {
    const onSuccess = (data) => {
      let newResources = [];
      let newMaxNumOfLinks = 0;
      const rows = data.result.rows;
      const length = rows.length;

      for (let i = 0; i < length; i++) {
        let userGuideResources = [];
        const resourceLength = rows[i].user_guide_resources.length;
        for (let j = 0; j < resourceLength; j++) {
          userGuideResources.push({
            id: rows[i].user_guide_resources[j].id,
            name: rows[i].user_guide_resources[j].name,
            url: rows[i].user_guide_resources[j].url,
          });
        }

        newMaxNumOfLinks = Math.max(newMaxNumOfLinks, userGuideResources.length);
        newResources.push({
          id: parseInt(rows[i].id),
          title: rows[i].title,
          image_url: rows[i].image_url,
          description: rows[i].description,
          user_guide_resources: userGuideResources,
        });
      }

      setResources(newResources.sort((a, b) => a.id - b.id));

      stopLoading();
    };

    const onError = (e) => {
      console.error("Error from fetching getUserGuides: Error = " + e);
      stopLoading();
    };

    startLoading();
    await getDataHelper("/v1/user-guides",
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess,
      onError);
  }

  useEffect(() => {
    async function fetchData() {
      await getUserGuides();
    }

    fetchData();
  }, []);

  return (
    <>
      <PageTitle title="Resources | Bloom IT" />
      <div className="container-content-fixed">
        <Header title="Resources" />
        {
          !isLoading
            ? <DisplayCards
              resources={resources} />
            : <LoadingIcon />
        }
      </div>
    </>
  );
};

export default Resources;
