import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { useState } from "react";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";

import Home from "./routes/Home";
import Team from "./routes/Team";
import Courses from "./routes/Courses";
import AboutUs from "./routes/AboutUs";
import FAQ from "./routes/Faq";
import Resources from "./routes/Resources";
import PersonalDataProtectionNotice from "./routes/PersonalDataProtectionNotice";
import MissingPage from "./routes/Missing";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollButton";
import CookieConsentAlert from "./components/CookieConsentAlert";

const App = () => {
  // Check whether user has already viewed and interact with the cookie notice
  // If they did, do not display the notice
  const acceptedCookieFlag =
    window.sessionStorage.getItem("viewedCookieNotice") === undefined
      ? true
      : window.sessionStorage.getItem("viewedCookieNotice") != "true";

  const [showCookieConsent, setShowCookieConsent] =
    useState(acceptedCookieFlag);

  function onCookieAccept() {
    window.sessionStorage.setItem("viewedCookieNotice", "true");
    if(Cookies.get("browserId") === undefined) {
      Cookies.set("browserId", uuidv4());
    }
    Cookies.set("acceptedCookies", true);
    setShowCookieConsent(false);
  }

  function onCookieDecline() {
    window.sessionStorage.setItem("viewedCookieNotice", "true");
    setShowCookieConsent(false);
  }

  return (
    <ThemeProvider
      breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]}
      minBreakpoint="xs"
    >
      <div className="container-app">
        <NavBar />
        {showCookieConsent ? (
          <CookieConsentAlert
            onAccept={onCookieAccept}
            onDecline={onCookieDecline}
          />
        ) : (
          <></>
        )}
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/team" element={<Team />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/resources" element={<Resources />} />
            <Route
              path="/data-protection-notice"
              element={<PersonalDataProtectionNotice />}
            />
            <Route path="*" element={<MissingPage />} />
          </Routes>
        </Router>
        <Footer />
        <ScrollButton />
      </div>
    </ThemeProvider>
  );
};

export default App;
