class TwoWayMap {
  constructor(map) {
     this.map = map;
     this.reverseMap = {};
     for(const key in map) {
        const value = map[key];
        this.reverseMap[value] = key;   
     }
  }
  getLanguage(key) { return this.map[key]; }
  getLanguageCode(key) { return this.reverseMap[key]; }
}

const classLanguageMappings = new TwoWayMap({
    "en": "English",
    "zh": "Chinese"
});

export function getLanguage(key) {
   return classLanguageMappings.getLanguage(key);
}

export function getLanguageCode(key) {
   return classLanguageMappings.getLanguageCode(key);
}
