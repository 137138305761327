/**
 * Summary. 
 * Check if date has expired
 * 
 * @param {*} userid
 * @returns session's token and date
 */
function checkDateExpired(date, expiry) {
  current = new Date();
  return date + expiry < current.getDate();
}

/**
 * Summary. 
 * Get current hosting address
 * 
 * @returns string that shows the corrent hosting address
 */
function getCurrentAddress() {
  return process.env.REACT_APP_PROJECT_ENVIRONMENT === "dev"
    ? process.env.REACT_APP_BLOOM_IT_API_URL_DEV
    : process.env.REACT_APP_BLOOM_IT_API_URL_RELEASE;
}

module.exports = {
  checkDateExpired,
  getCurrentAddress
};
