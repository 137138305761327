import Accordion from "react-bootstrap/Accordion";

import Header from "../components/Header";
import PageTitle from "../components/PageTitle";

import imgQnsMark from "../images/faq_question.svg";

const FAQ = () => {
  const content = [
    {
      topic: "Can I claim SkillsFuture?",
      content:
        "Yes, all courses are SkillsFuture claimable. Please refer to our guide on how you can claim upon registering",
    },
    {
      topic:
        "I am 50 years old and below, can I still sign up for the courses?",
      content:
        "Yes, you can. However, you are required to foot the full course fees of SGD50 as the funding is only applicable for seniors who are Singapore Citizens or Permanent Residents, ages 50 years and above.",
    },
    {
      topic:
        "Since I need to pay SGD50 for the full course fees if I am 50 years old and below, can I claim all using SkillsFuture?",
      content: "Yes, you can.",
    },
    {
      topic:
        "I am looking to have a 1-1 private class or a smaller group setting class, do Bloom IT provide such services?",
      content:
        "Yes, we do! Please contact us via WhatsApp and we will arrange something for you. 😊 However, do note that the fees may be different for private lessons.",
    },
  ];

  return (
    <>
      <PageTitle title="FAQs | Bloom IT" />
      <div className="container-content-fixed">
        <Header title="Frequently Asked Questions" />
        <div className="container-image">
          <img
            className="faq-img"
            src={imgQnsMark}
            alt="FAQ question mark illustration"
          />
        </div>
        <p>Have any questions? Read popular answers below:</p>
        <Accordion style={{ margin: "10px 0px" }}>
          {content.map(function (item, idx) {
            return (
              <Accordion.Item eventKey={`${idx}`}>
                <Accordion.Header className="accordion-qns">
                  {item.topic}
                </Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </>
  );
};

export default FAQ;
