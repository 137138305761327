import PageTitle from "../components/PageTitle";
import Header from "../components/Header";

import img404 from "../images/404-startled.svg";

const MissingPage = () => {
  return (
    <>
      <PageTitle title="Page Not Found" />
      <div className="container-content-fixed">
        <Header title="Oops! Page not found." />
        <div className="page-not-found-content">
          <img className="startled-img" src={img404} alt="404 error image" />
          <p>
            We can't seem to find the page you were looking for.
          </p>
          <div className="page-not-found-btn-grp">
            <a className="btn btn-custom-accent"href="javascript:window.history.back();">Previous page</a>
            <a className="btn btn-custom-secondary" href="/">Back to home</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingPage;
