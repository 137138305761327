import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Stack from 'react-bootstrap/Stack';

import { IoMenu } from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';

import { DeviceSize } from '../configs/Device.js';

import websiteLogoSrc from '../images/Logo-01-Horizontal-350x75.png';

const MenuList = () => {
  return (
    <Fragment>
      <li className="navbar-item navbar-item--1"><a href='/'>Home</a></li>
      <li className="navbar-item navbar-item--2"><a href='/courses'>Courses</a></li>
      <li className="navbar-item navbar-item--3"><a href='/about-us'>About Us</a></li>
      <li className="navbar-item navbar-item--4"><a href='/team'>Team</a></li>
      <li className="navbar-item navbar-item--5"><a href='/resources'>Resources</a></li>
      <li className="navbar-item navbar-item--6"><a href='/faq'>FAQ</a></li>
      <li className="navbar-item navbar-item--7"><a className="btn btn-custom-secondary" href="/courses">Sign Up</a></li>
    </Fragment>
  );
}

const SideMenu = ({ isOpen, onHide }) => {
  return (
    <Offcanvas
      show={isOpen}
      onHide={onHide}
      scroll={true}
      backdrop={true}
      placement={'end'} >
      <Offcanvas.Header closeButton>
        <div className="nav-main-item nav-main-item--1">
          <a href='/'><img className="nav-logo" src={websiteLogoSrc} alt=""></img></a>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack gap={4}>
          <MenuList />
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const MobileNavLinks = () => {

  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  return (
    <div className="nav-main-item nav-main-item--2">
      <nav className="navbar-container">
        {!isSideMenuOpen
          ? <Button variant="light" onClick={() => setIsSideMenuOpen(true)}><IoMenu fontSize={'2em'} /></Button>
          : <></>}
      </nav>
      {isSideMenuOpen
        ? <SideMenu isOpen={isSideMenuOpen} onHide={() => setIsSideMenuOpen(false)} />
        : <></>}
    </div>
  );
}

const WebNavLinks = () => {
  return (
    <div className="nav-main-item nav-main-item--2">
      <nav className="navbar-container">
        <ul className="navbar-list">
          <MenuList />
        </ul>
      </nav>
    </div>
  );
}

const NavBar = () => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.laptop });

  return (
    <div className="nav-main-container">
      <div className="nav-main-item nav-main-item--1">
        <a href='/'><img className="nav-logo" src={websiteLogoSrc} alt="Bloom It logo"></img></a>
      </div>
      {isMobile ? <MobileNavLinks /> :
        <WebNavLinks />
      }
    </div>
  );
};

export default NavBar;
