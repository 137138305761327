import { Fragment } from "react";
import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
  FaWhatsapp,
  FaRegCalendar,
  FaRegClock,
  FaLocationDot,
  FaRegUser
} from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";

import { getLanguage } from "../helpers/LanguageTranslation";
import { postDataHelper } from "../requests/PostRequests";

const ClassCard = ({ course, onTriggerFlip }) => {
  const [classes, setClasses] = useState([]);

  // Fetch entries from the server
  const getClasses = async (courseId) => {
    const onSuccess = (data) => {
      let newClasses = [];
      const rows = data.result.rows;
      const length = rows.length;

      for (let i = 0; i < length; i++) {
        newClasses.push({
          course_id: parseInt(rows[i].course_id),
          name: rows[i].name,
          location_name: rows[i].location_name,
          address: rows[i].address,
          postal: rows[i].postal,
          start_date: rows[i].start_date,
          end_date: rows[i].end_date,
          language: rows[i].language,
          number_of_sessions: rows[i].number_of_sessions
        });
      }

      setClasses(newClasses);
    };

    // Get classes based on the courseId
    await postDataHelper("/v1/classes",
      { courseId: courseId },
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess);
  };

  // Run once and load all the classes related to this course
  useEffect(() => {
    async function fetchData() {
      await getClasses(course.id);
    }

    fetchData();
  }, []);

  return (
    <Fragment key={`${course.id}_class_card_fragment`}>
      <div key={`${course.id}_class_header`} className="class-card-header">
        <Card.Title
          style={{ margin: "4px 0px", color: "var(--text-dark-color)" }}
        >
          {course.name}
        </Card.Title>
        <Card.Subtitle
          style={{
            marginTop: "8px",
            color: "var(--text-color)",
            fontSize: "1.1em",
          }}
        >
          <b>Course Code:</b> {course.course_code}
        </Card.Subtitle>
      </div>
      <span className="horz-line"></span>
      <div className="class-card-body" style={{ overflow: "auto" }}>
        {classes.length > 0 ? (
          classes.map(function (c, i) {
            /*
            NOTE: start_date and end_date values contains class date range (date part) and class timing (time part)
           */
            const startDateFormatted = new Date(c.start_date).toLocaleDateString(
              "en-sg",
              { year: "numeric", month: "short", day: "numeric" }
            );
            const endDateFormatted = new Date(c.end_date).toLocaleDateString(
              "en-sg",
              { year: "numeric", month: "short", day: "numeric" }
            );
            const startTimeFormatted = new Date(c.start_date).toLocaleTimeString(
              "en-us",
              { hour: "2-digit", minute: "2-digit" }
            );
            const endTimeFormatted = new Date(c.end_date).toLocaleTimeString(
              "en-us",
              { hour: "2-digit", minute: "2-digit" }
            );
            const langFormatted = getLanguage(c.language);

            const whatsappMessage = `Hi Bloom IT! I am interested in this class, could you help me with this?
%0ACourse code: ${course.course_code}
%0ACourse name: ${course.name}
%0ADate/Time: ${startDateFormatted} at ${startTimeFormatted}
%0ALanguage: ${langFormatted}
%0AVenue: ${c.location_name}`;

            return (
              <div key={`${course.id}_${i}_card_div`}>
                <div
                  key={`${course.id}_${i}_class_card`}
                  style={{ padding: "1.25rem 1rem" }}
                >
                  <Card.Text>
                    <FaRegCalendar className="class-icn" /> {startDateFormatted}{" "}
                    - {endDateFormatted}
                  </Card.Text>
                  <Card.Text>
                    <FaRegClock className="class-icn" /> {startTimeFormatted}-
                    {endTimeFormatted} ({c.number_of_sessions} session
                    {c.number_of_sessions > 1 ? "s" : ""})
                  </Card.Text>
                  <Card.Text>
                    <FaLocationDot className="class-icn" /> {c.address}{" "}
                    {c.postal}
                  </Card.Text>
                  <Card.Text>
                    <IoLanguage className="class-icn" /> {langFormatted}
                  </Card.Text>
                  <Card.Text>
                    <FaRegUser className="class-icn" /> {c.name}
                  </Card.Text>
                  <div className="class-enquire-btn">
                    <a
                      className="btn-whatsapp"
                      href={`https://wa.me/6587793669?text=${whatsappMessage}`}
                    >
                      <div className="btn-whatsapp-text">
                        <FaWhatsapp className="whatsapp-icon" />
                        Enquire Now
                      </div>
                    </a>
                  </div>
                </div>
                {classes.length - 1 !== i ? (
                  <span
                    className="horz-line"
                    style={{ display: "block" }}
                  ></span>
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : (
          <Card.Body>
            <Card.Text>No classes available now, check back again!</Card.Text>
          </Card.Body>
        )}
      </div>
      <Card.Footer className="card-footer-btn-container">
        <Button
          variant="custom-accent bottom"
          onClick={() => onTriggerFlip(course.id)}
        >
          Back
        </Button>
      </Card.Footer>
    </Fragment>
  );
};

export default ClassCard;
