import { useState } from "react";
import { FaCircle, FaChevronUp } from "react-icons/fa6";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="scroll-to-top-btn">
      <span
        className="scroll-to-top-btn-grp"
        onClick={scrollToTop}
        style={{
          display: visible ? "grid" : "none",
          position: "relative",
          opacity: "80%",
        }}
      >
        <FaCircle
          className="scroll-to-top-icon"
          textAnchor="middle"
          alignmentBaseline="middle"
        />
        <FaChevronUp
          textAnchor="middle"
          alignmentBaseline="middle"
          style={{
            fontSize: ".5em",
            position: "absolute",
            left: ".5em",
            bottom: ".5em",
            fill: "#fff",
          }}
        />
      </span>
    </div>
  );
};

export default ScrollButton;
