import websiteLogoSrc from "../images/Logo-Text.png";

import { FaWhatsapp, FaLinkedinIn, FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa6";

const Footer = ({ }) => {
  return (
    <div className="footer-main-container">
      <div className="footer-sub-container">
        <p className="footer-text footer-text--1">LET'S BLOOM TOGETHER</p>
        <p className="footer-text footer-text--2">Request More Information</p>
        <p className="footer-text footer-text--3">
          Bloom IT (Pte. Ltd.) is a Singapore-based social enterprise to reignite the people's passion for IT
          and help them to blossom.
        </p>
        <div className="footer-text footer-text--4">
          <a className="btn-whatsapp" href="https://wa.me/6587793669?text=I+would+like+to+find+out+more+about+Bloom+IT!">
            <div className="btn-whatsapp-text">
              <FaWhatsapp className="whatsapp-icon" />
              Contact Us
            </div>
          </a>
        </div>
        <p className="footer-text footer-text--5">
          Alternatively, you can make your enquiries at <a href="tel:+6587793669">+65 8779 3669</a> or <a href="mailto:admin@bloomit.sg">admin@bloomit.sg</a>
        </p>
        <p className="footer-text footer-text--6">
          © 2024 BLOOM IT (PTE. LTD.) | 22 SIN MING LANE
        </p>
        <div className="footer-text"><hr className="footer-horiz-line" /></div>

        <div className="footer-nav-main-container">
          <div className="footer-nav-main-item footer-nav-main-item--1">
            <a href="/"><img className="footer-logo" src={websiteLogoSrc} alt="Bloom It logo"></img></a>
          </div>
          <div className="footer-nav-main-item footer-nav-main-item--2">
            <nav className="navbar-container">
              <ul className="navbar-list footer-navbar-list">
                <li className="navbar-item "><a href="/courses">Courses</a></li>
                <li className="navbar-item"><a href="/about-us">About Us</a></li>
                <li className="navbar-item"><a href="/team">Team</a></li>
                <li className="navbar-item"><a href="/resources">Resources</a></li>
                <li className="navbar-item"><a href="/faq">FAQ</a></li>
              </ul>
            </nav>
            <span className="navbar-item">
              <a href="/data-protection-notice">Privacy and Cookie Policies</a>
            </span>
          </div>
          <div className="footer-nav-main-item footer-nav-main-item--3">
            <nav className="socials-container">
              <ul className="socials-list">
                <li className="socials-item"><a href="https://www.instagram.com/bloomitsg/"><FaInstagram className="socials-icon" /></a></li>
                <li className="socials-item"><a href="https://www.facebook.com/people/Bloom-IT/61558775499852/"><FaFacebookF className="socials-icon" /></a></li>
                {/* <li className="socials-item"><a href="/"><FaLinkedinIn className="socials-icon" /></a></li> */}
                {/* <li className="socials-item"><a href="/"><FaTiktok className="socials-icon" /></a></li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
