import { getCurrentAddress } from '../helpers/utilities';

/**
 * Retrieve results from the API using a GET request
 * @param {*} targetUrl 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export async function getData(targetUrl, onSuccess, onError) {
  const url = getCurrentAddress();
  await fetch(url + targetUrl)
    .then((response) => response.json())
    .then(onSuccess)
    .catch(onError);
}

/**
 * Upload data to the API with a POST request
 * @param {*} targetUrl 
 * @param {*} data 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export async function postData(targetUrl, data, onSuccess, onError) {
  const url = getCurrentAddress();
  await fetch(url + targetUrl, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then(onSuccess)
    .catch(onError);
}

/**
 * getData helper to implement onSuccess and onError message
 * Reduce the need to implement onErrror everytime
 * @param {*} targetUrl 
 * @param {*} onTriggerMessage 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export async function getDataHelper(targetUrl, onTriggerMessage, onSuccess, onError) {

  const onErrorImpl = (e) => {
    if (onError != undefined) {
      onError(e);
    } else {
      onTriggerMessage(
        "Failed to retrieve data",
        `Error from fetching ${targetUrl}: Error = ${e}`,
        "danger"
      );
    }
  };

  const onSuccessImpl = (data) => {
    if (data.status === 3) {
      onSuccess(data);
    } else {
      onErrorImpl(data);
    }
  };

  await getData(targetUrl, onSuccessImpl, onErrorImpl);
}

/**
 * postData helper to implement onSuccess and onError message
 * Reduce the need to implement onErrror everytime
 * @param {*} targetUrl 
 * @param {*} inputData 
 * @param {*} onTriggerMessage 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export async function postDataHelper(targetUrl, inputData, onTriggerMessage, onSuccess, onError) {
  const onErrorImpl = (e) => {
    if (onError != undefined) {
      onError(e);
    } else {
      onTriggerMessage(
        "Failed to retrieve data",
        `Error from fetching ${targetUrl}: Error = ${e}`,
        "danger"
      );
    }
  };

  const onSuccessImpl = (data) => {
    if (data.status === 3) {
      onSuccess(data);
    } else {
      onErrorImpl(data);
    }
  };

  await postData(targetUrl, inputData, onSuccessImpl, onErrorImpl);
}
