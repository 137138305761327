import { Fragment, useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Pagination from "react-bootstrap/Pagination";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormLabel from "react-bootstrap/esm/FormLabel";
import Overlay from 'react-bootstrap/Overlay';
import Toast from 'react-bootstrap/Toast';
import { FaRegHeart, FaHeart, FaTriangleExclamation } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { RxCross2, RxPlus } from "react-icons/rx";
import Cookies from "js-cookie";

import PageTitle from "../components/PageTitle";
import LoadingIcon from "../components/LoadingIcon";
import Header from "../components/Header";
import ClassCard from "../components/ClassCard";
import { timeout } from "../helpers/Sleep";
import { getLanguage } from "../helpers/LanguageTranslation";
import { getDataHelper, postDataHelper } from "../requests/PostRequests";

const CourseTagBadge = ({ id, tags, onCheckboxHandler }) => {
  return (
    <>
      {tags.map(function (c, i) {
        return (
          <Badge
            className="course-card-tag-badge"
            onClick={() => onCheckboxHandler(c.tag)}
            key={`${id}_${i}_badget`}
            bg="custom-primary"
            style={{ padding: "3px 8px", margin: "3px" }}
            pill={true}
          >
            {c.tag}
          </Badge>
        );
      })}
    </>
  );
};

const CourseCard = ({ course, flags, onTriggerFlip, onCheckboxHandler }) => {
  // Using dictionary as a workaround as the card is not refreshing when pagination is used
  // We will tag the original isLiked value to the course id, so when we "like" the card
  // it will be tagged to the id instead of this component `CourseCard`
  const [isLiked, setIsLiked] = useState({ [course.id]: course.isLiked });
  const [showLiked, setShowLiked] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);
  const [showError, setShowError] = useState(false);
  const target = useRef(null);

  async function addLike(courseId, userId) {
    const onSuccess = (data) => {
      // Update status
      setIsLiked({ ...isLiked, [courseId]: true });
      setShowLiked(true);
    };

    const onError = (e) => {
      console.error("Error from fetching addLike: Error = " + e);
      setShowError(true);
    };

    if (courseId === undefined || userId === undefined) {
      setShowError(true);
      return;
    }

    await postDataHelper(
      "/v1/courses/like/add",
      { courseId: courseId, userId: userId },
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess,
      onError
    );
  }

  async function removeLike(courseId, userId) {
    const onSuccess = (_) => {
      // Update status
      setIsLiked({ ...isLiked, [courseId]: false });
      setShowRemoved(true);
    };

    const onError = (e) => {
      console.error("Error from fetching removeLike: Error = " + e);
      setShowError(true);
    };

    if (courseId === undefined || userId === undefined) {
      setShowError(true);
      return;
    }

    await postDataHelper(
      "/v1/courses/like/delete",
      { courseId: courseId, userId: userId },
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess,
      onError
    );
  }

  const browserId = Cookies.get("browserId");
  const backgroundUrl = `url(${course.image_url})`;
  const icon = !isLiked[course.id] ? (
    <FaRegHeart onClick={() => addLike(course.id, browserId)} />
  ) : (
    <FaHeart onClick={() => removeLike(course.id, browserId)} />
  );

  return !flags[course.id] ? (
    <>
      <div
        className="card-image"
        style={{ backgroundImage: backgroundUrl, flexShrink: "0" }}
      ></div>
      <Card.Body>
        <Card.Title>
          <div className="course-title-grp">
            {course.name}
            <span ref={target} className="course-like-icon" key={`${course.id}_liked_icon`}>{icon}</span>
            <Overlay target={target.current} show={showLiked} placement="left">
              {
                <Toast className="liked-pop-toast" onClose={() => setShowLiked(false)} delay={3000} autohide>
                  <Toast.Header>
                    <FaHeart style={{ marginRight: "0.5rem" }} />
                    <strong className="me-auto">Course liked!</strong>
                  </Toast.Header>
                  <Toast.Body>Thank you for expressing interest in this course, your feedback helps us create better courses for you. 😊</Toast.Body>
                </Toast>
              }
            </Overlay>
            <Overlay target={target.current} show={showRemoved} placement="left">
              {
                <Toast className="liked-pop-toast" onClose={() => setShowRemoved(false)} delay={3000} autohide>
                  <Toast.Header>
                    <FaRegHeart style={{ marginRight: "0.5rem" }} />
                    <strong className="me-auto">Course un-liked!</strong>
                  </Toast.Header>
                  <Toast.Body>Thank you, your feedback helps us create better courses for you. 😊</Toast.Body>
                </Toast>
              }
            </Overlay>
            <Overlay target={target.current} show={showError} placement="left">
              {
                <Toast className="liked-pop-toast" onClose={() => setShowError(false)} delay={3000} autohide>
                  <Toast.Header>
                    <FaTriangleExclamation style={{ marginRight: "0.5rem" }} />
                    <strong className="me-auto">An error occurred!</strong>
                  </Toast.Header>
                  <Toast.Body>Please try again later and ensure that you have allowed cookies.</Toast.Body>
                </Toast>
              }
            </Overlay>
          </div>
        </Card.Title>
        <Card.Text>
          <CourseTagBadge tags={course.tags} id={course.id} onCheckboxHandler={onCheckboxHandler} />
        </Card.Text>
        <Card.Text>{course.description}</Card.Text>
      </Card.Body>
      <Card.Footer className="card-footer-btn-container">
        <Button
          variant="custom-secondary bottom"
          onClick={() => onTriggerFlip(course.id)}
        >
          Available Classes
        </Button>
      </Card.Footer>
    </>
  ) : (
    <ClassCard course={course} onTriggerFlip={onTriggerFlip} />
  );
};

const DisplayCards = ({ courses, flags, onTriggerFlip, onCheckboxHandler }) => {
  return courses.map(function (course, idx) {
    return (
      <Col key={`course_col_${idx}`}>
        <Card
          className="h-100"
          key={`course_card_${idx}`}
          border="muted"
          style={{
            overflow: "auto",
            margin: "10px",
          }}
        >
          <CourseCard
            course={course}
            flags={flags}
            onTriggerFlip={onTriggerFlip}
            onCheckboxHandler={onCheckboxHandler}
          />
        </Card>
      </Col>
    );
  });
};

const CourseHeader = ({
  coursesLength,
  currentLanguage,
  onTriggerChangeLanguage,
}) => {
  return (
    <Fragment>
      <Header title={`Courses (${coursesLength})`} />
      <OverlayTrigger
        key={"left"}
        placement={"left"}
        overlay={<Tooltip id={`tooltip-left`}>Click to translate!</Tooltip>}
      >
        <Button
          variant="light"
          className="courses-translate-btn"
          onClick={() =>
            onTriggerChangeLanguage(currentLanguage == "en" ? "zh" : "en")
          }
        >
          <IoLanguage className="courses-translate-icn" />
          <span className="courses-translate-lang">
            {getLanguage(currentLanguage)}
          </span>
        </Button>
      </OverlayTrigger>
    </Fragment>
  );
};

const CoursePagination = ({ numOfPages, currentPage, onUpdate }) => {
  return (
    <div className="pagination-main-container">
      <Pagination size="lg">
        <Pagination.Prev onClick={() => onUpdate(currentPage - 1)} />
        {Array.from(Array(numOfPages), (e, i) => {
          const pageNumber = i + 1;
          return (
            <Pagination.Item
              onClick={() => onUpdate(i)}
              active={currentPage == i}
              key={`pagination_page_${pageNumber}`}
            >
              {pageNumber}
            </Pagination.Item>
          );
        })}
        <Pagination.Next onClick={() => onUpdate(currentPage + 1)} />
      </Pagination>
    </div>
  );
};

const SearchForm = ({
  tags,
  searchParameters,
  onChangeHandler,
  onCheckboxHandler,
  onClearSelectedTags
}) => {
  const { searchText } = searchParameters;

  return (
    <Form>
      <FloatingLabel controlId="floatingInput" label="Search" className="mb-3">
        <Form.Control
          type="text"
          placeholder="Search..."
          name="searchText"
          value={searchText}
          onChange={onChangeHandler}
        />
      </FloatingLabel>
      <Accordion activeKey={searchParameters.tags.length != 0 ? "0" : undefined}>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={onClearSelectedTags}>Advanced Search</Accordion.Header>
          <Accordion.Body>
            <FormLabel>Selected tags:</FormLabel>
            <div>
              <Badge
                className="p-2 tags-badge"
                onClick={() => onClearSelectedTags()}
                key={"clear_all_option_badge_cancel"}
                bg="secondary"
                style={{ padding: "3px 8px", margin: "3px" }}
                pill={true}
              >
                <div style={{ display: "flex", color: "#fff" }}>
                  Clear All
                  <RxCross2
                    className="tags-cross-icon"
                    key={"clear_all_option_cancel_icon"}
                    style={{ marginLeft: "5px" }}
                  />
                </div>
              </Badge>
              {Object.keys(tags).map(function (tag) {
                return tags[tag] ? (
                  <Badge
                    className="p-2 tags-badge"
                    onClick={() => onCheckboxHandler(tag)}
                    key={`${tag}_option_badge_cancel`}
                    bg="custom-secondary"
                    style={{ padding: "3px 8px", margin: "3px" }}
                    pill={true}
                  >
                    <div style={{ display: "flex", color: "#fff" }}>
                      {tag}
                      <RxCross2
                        className="tags-cross-icon"
                        key={`${tag}_option_cancel_icon`}
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </Badge>
                ) : (
                  <Fragment
                    key={`${tag}_cancel_fragment_placeholder`}
                  ></Fragment>
                );
              })}
            </div>
            <br />
            <FormLabel>Available tags:</FormLabel>
            <div>
              {Object.keys(tags).map(function (tag) {
                return !tags[tag] ? (
                  <Badge
                    className="p-2 tags-badge"
                    onClick={() => onCheckboxHandler(tag)}
                    key={`${tag}_option_badge_add`}
                    bg="custom-primary"
                    style={{ padding: "3px 8px", margin: "3px" }}
                    pill={true}
                  >
                    <div style={{ display: "flex" }}>
                      {tag}
                      <RxPlus
                        className="tags-plus-icon"
                        key={`${tag}_option_add_icon`}
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </Badge>
                ) : (
                  <Fragment key={`${tag}_add_fragment_placeholder`}></Fragment>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Form>
  );
};

function courseFilters(input, searchParameters) {
  // List of filters we want to use for the course
  const filterFunctions = [filterByTags, filterByText];

  function filterByTags(searchParameters, course) {
    // If there is no tags to search
    // We will ignore it
    if (
      searchParameters.tags === undefined ||
      searchParameters.tags.length == 0
    ) {
      return true;
    }

    return course.tags.some((r) => searchParameters.tags.includes(r.tag));
  }

  function filterByText(searchParameters, course) {
    if (
      searchParameters.searchText === undefined ||
      searchParameters.searchText.length == 0
    ) {
      return true;
    }

    const searchText = searchParameters.searchText.toLowerCase();
    return (
      course.course_code.toLowerCase().includes(searchText) ||
      course.name.toLowerCase().includes(searchText)
    );
  }

  return input.filter((course) =>
    filterFunctions.every((fn) => fn(searchParameters, course))
  );
}

const Courses = () => {
  // For pagination
  const pageLimit = 12;
  const [numOfPages, setNumOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [shownCourses, setShownCourses] = useState([]);

  // For courses
  const [courseFlags, setCourseFlags] = useState({});
  const [cachedCourses, setCachedCourses] = useState({ en: [], zh: [] });
  const [currentLanguage, setCurrentLanguage] = useState("en");

  // For filters
  const [searchParameters, setSearchTerms] = useState({
    searchText: "",
    tags: [],
  });
  const [tags, setTags] = useState({});

  // For loading icon
  const [isLoading, setIsLoading] = useState(true);
  const startLoading = () => setIsLoading(true);
  const stopLoading = async () => {
    const timeoutDuration = 200;
    await timeout(timeoutDuration);
    setIsLoading(false);
  };

  async function getTags() {
    const onSuccess = (data) => {
      let newTags = {};
      const rows = data.result.rows;
      const length = rows.length;

      for (let i = 0; i < length; i++) {
        newTags[rows[i].tag] = false;
      }

      setTags(newTags);
    };

    await getDataHelper("/v1/courses/tags",
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess);
  }

  // For classes
  function onTriggerFlip(courseId) {
    courseFlags[courseId] = !courseFlags[courseId];
    setCourseFlags(Object.create(courseFlags));
  }

  function updateShowCourses(offset) {
    updateShowCoursesImpl(filteredCourses, offset);
  }

  function updateShowCoursesImpl(inputCourses, offset) {
    // Check to ensure we do not go out of bound
    const maximumPage = Math.ceil(inputCourses.length / pageLimit);
    if (offset < 0 || (maximumPage > 0 && offset == maximumPage)) {
      return;
    }
    // We want to get the starting position of the courses in the next page
    const currentOffset = offset * pageLimit;
    // Check how many remaining courses we have, else that if we are at the last page, we do not have courses
    // that are out of bound
    const remainingCourses = inputCourses.length - offset * pageLimit;

    // Update all the states
    setShownCourses(
      inputCourses.slice(
        currentOffset,
        currentOffset + Math.min(pageLimit, remainingCourses)
      )
    );
    setCurrentPage(offset);
    setNumOfPages(Math.floor(inputCourses.length / pageLimit + 1));

    // Scroll to top once we set new page
    window.scrollTo(0, 0);
  }

  async function onTriggerChangeLanguage(language) {
    setCurrentLanguage(language);

    if (cachedCourses[language].length != 0) {
      startLoading();
      const languageCourses = [
        ...courseFilters(cachedCourses[language], searchParameters),
      ];
      setCurrentPage(0);
      setNumOfPages(Math.floor(languageCourses.length / pageLimit + 1));
      setShownCourses(
        languageCourses.slice(0, Math.min(pageLimit, languageCourses.length))
      );
      setFilteredCourses(languageCourses);
    } else {
      await getCourses(language);
    }
    stopLoading();
  }

  const filterCourse = (newSearchParameters) => {
    startLoading();
    const newFiltered = [
      ...courseFilters(cachedCourses[currentLanguage], newSearchParameters),
    ];

    // Update all the states
    setSearchTerms(newSearchParameters);
    setFilteredCourses(newFiltered);
    updateShowCoursesImpl(newFiltered, 0);
    stopLoading();
  };

  const onChangeHandler = (e) => {
    const newTerms = { ...searchParameters, [e.target.name]: e.target.value };
    filterCourse(newTerms);
  };

  const onCheckboxHandler = (name) => {
    let newTerms = { ...searchParameters };
    if (newTerms.tags.includes(name)) {
      newTerms.tags = newTerms.tags.filter((tag) => tag != name);
    } else {
      newTerms.tags.push(name);
    }

    let newTags = { ...tags };
    newTags[name] = !tags[name];
    setTags(newTags);
    filterCourse(newTerms);
  };

  const onClearSelectedTags = () => {
    // Update selected tags
    let newTags = { ...tags };
    Object.keys(newTags).forEach(tag => newTags[tag] = false);
    setTags(newTags);

    let newTerms = { ...searchParameters };
    newTerms.tags = [];
    // Update the course based on empty tags
    filterCourse(newTerms);
  }

  async function getCourses(language, userId) {
    const onSuccess = (data) => {
      let newCourses = [];
      let newCourseFlags = {};
      const rows = data.result.rows;
      const length = rows.length;

      for (let i = 0; i < length; i++) {
        let tags = [];
        const tagsLength = rows[i].tags.length;
        for (let j = 0; j < tagsLength; j++) {
          tags.push({
            tag: rows[i].tags[j].tag,
            index: rows[i].tags[j].index,
          });
        }

        newCourses.push({
          id: parseInt(rows[i].id),
          course_code: rows[i].course_code,
          name: rows[i].name,
          description: rows[i].description,
          image_url: rows[i].image_url,
          tags: tags,
          isLiked: rows[i].is_liked,
        });
      }

      newCourses.forEach(function (course) {
        newCourseFlags[course.id] = false;
      });

      newCourses = newCourses.sort((a, b) => a.id - b.id);

      setCurrentPage(0);
      setNumOfPages(Math.floor(newCourses.length / pageLimit + 1));
      setShownCourses(
        newCourses.slice(0, Math.min(pageLimit, newCourses.length))
      );
      setFilteredCourses(newCourses);

      setCachedCourses({ ...cachedCourses, [language]: newCourses });
      setCourseFlags(newCourseFlags);
    };

    startLoading();

    await postDataHelper("/v1/courses",
      { language: language, userId: userId },
      (title, content, type) => console.log(`${title}, ${content}, ${type}`),
      onSuccess);
  }

  // Run on instantiation of this page
  useEffect(() => {
    async function fetchData() {
      await getCourses(currentLanguage, Cookies.get("browserId"));
      await getTags();
      stopLoading();
    }

    fetchData();
  }, []);

  return (
    <>
      <PageTitle title="Courses | Explore Classes | Bloom IT" />
      <div className="container-content-fixed">
        <div className="courses-hdr-grp">
          <CourseHeader
            currentLanguage={currentLanguage}
            coursesLength={filteredCourses.length}
            onTriggerChangeLanguage={onTriggerChangeLanguage}
          />
        </div>
        <SearchForm
          tags={tags}
          searchParameters={searchParameters}
          onChangeHandler={onChangeHandler}
          onCheckboxHandler={onCheckboxHandler}
          onClearSelectedTags={onClearSelectedTags}
        />
        <br />
        {!isLoading ? (
          filteredCourses.length > 0 ? (
            <Fragment key="course_result_fragment">
              <Row xs={1} sm={1} md={2} xl={3} xxl={4} className="g-4">
                <DisplayCards
                  courses={shownCourses}
                  flags={courseFlags}
                  onTriggerFlip={onTriggerFlip}
                  onCheckboxHandler={onCheckboxHandler}
                />
              </Row>
              <CoursePagination
                currentPage={currentPage}
                numOfPages={numOfPages}
                onUpdate={updateShowCourses}
              />
            </Fragment>
          ) : (
            <div className="empty-container">
              Sorry, No results found. Please try again.
            </div>
          )
        ) : (
          <LoadingIcon />
        )}
      </div>
    </>
  );
};

export default Courses;
